<template>
  <div>

    <section class="header-haberler">

      <h3>{{ lang.belgeler }}</h3>
      <h2>{{ lang.bilgitoplumhizmeti }} </h2>

      <div class="bilgiToplum">
        <table>
          <tbody>
          <tr>
            <td data-label="Şirket Türü"><span>Şirket Türü</span><span>HALKA KAPALI ANONİM</span></td>
            <td>HALKA KAPALI ANONİM ŞİRKET</td>
          </tr>
          <tr>
            <td data-label="Ticaret Sicil Memurluğu"><span>Ticaret Sicil Memurluğu</span><span>KONYA</span></td>
            <td>KONYA</td>
          </tr>
          <tr>
            <td data-label="Ticaret Ünvanı"><span>Ticaret Ünvanı</span><span>GESAŞ GENEL GIDA SANAYİ VE TİCARET ANONİM ŞİRKETİ</span></td>
            <td>GESAŞ GENEL GIDA SANAYİ VE TİCARET ANONİM ŞİRKETİ</td>
          </tr>
          <tr>
            <td data-label="İletişim Bİlgileri"><span>İletişim Bİlgileri</span><span>	0332-235 88 82</span></td>
            <td>	0332-235 88 82</td>
          </tr>
          <tr>
            <td data-label="Ödenen Sermaye Miktarı"><span>Ödenen Sermaye Miktarı</span><span>15.000.000 TL</span></td>
            <td>15.000.000 TL</td>
          </tr>
          <tr>
            <td data-label="Vergi Dairesi"><span>Vergi Dairesi</span><span>SELÇUK</span></td>
            <td>SELÇUK</td>
          </tr>
          <tr>
            <td data-label="Sektör"><span>Sektör</span><span>	SUSAM - TAHİN - HELVA - ÇİKOLATA - REÇEL - LOKUM İMALATI</span></td>
            <td>	SUSAM - TAHİN - HELVA - ÇİKOLATA - REÇEL - LOKUM İMALATI</td>
          </tr>

          </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <td data-label="Mersis"><span>Mersis</span><span>0394005104400011</span></td>
            <td>0394005104400011</td>
          </tr>
          <tr>
            <td data-label="Ticaret Sicil Numarası"><span>Ticaret Sicil Numarası</span><span>7829</span></td>
            <td>7829</td>
          </tr>
          <tr>
            <td data-label="Adres"><span>Adres</span><span>ULUBATLI HASAN MAHALLESİ ANKARA CADDESİ NO: 146 KARATAY / KONYA</span></td>
            <td>ULUBATLI HASAN MAHALLESİ ANKARA CADDESİ NO: 146 KARATAY / KONYA</td>
          </tr>
          <tr>
            <td data-label="Taahhüt Edilen Sermaye Miktarı"><span>Taahhüt Edilen Sermaye Miktarı</span><span>15.000.000 TL</span></td>
            <td>15.000.000 TL</td>
          </tr>
          <tr>
            <td data-label="Tescil Tarihi"><span>Tescil Tarihi</span><span>20/03/1974</span></td>
            <td>20/03/1974</td>
          </tr>
          <tr>
            <td data-label="Vergi Numarası"><span>Vergi Numarası</span><span>3940051044</span></td>
            <td>3940051044</td>
          </tr>
          <tr>
            <td data-label="E-Şirket"><span>E-Şirket</span><span><a href="https://www.belgemodul.com/sirket/1248" target="_blank">Tıklayınız</a></span></td>
            <td><a href="https://www.belgemodul.com/sirket/1248" target="_blank">Tıklayınız</a></td>
          </tr>

          </tbody>
        </table>
      </div>

      <br>
      <h2>{{ lang.belgeler }}</h2>
    </section>
    <section class="duyurular-haberler">
      <div id="duyurular">
        <div v-for="(item, index) in docs" @click="openLightboxOnSlide(index+1)" class="duyuru"
                     data-aos="fade-up"
                     data-aos-offset="250"
                     data-aos-delay="0"
                     :data-aos-duration="'5'+index+'0'"
                     data-aos-easing="ease-in"
                     data-aos-mirror="true"
                     data-aos-once="false"
                     data-aos-anchor-placement="center-bottom">
          <div class="foto">
            <img :src="item.img" :alt="item.heading">
          </div>
          <div class="cart">
            <h3>{{ item.heading }}</h3>
          </div>
        </div>

      </div>

      <img id="bgcizgi" height="auto" src="../../../public/assets/img/kirmizi-cizgi.png" width="100%"/>
      <FsLightbox
          :toggler="toggler"
          :slide="slide"
          type="image"
          :onClose="fsClose"
          :onOpen="fsOpen"
          :onSlideChange="change"
          :sources="CustomSource"/>
      <a @click="download()" :class="{df: sss}" class="downloadImage">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
          <g>
	<path class="st0" d="M25.5,19.1V26H4.5v-6.8h-4V28c0,1.1,0.9,2,2,2h25c1.1,0,2-0.9,2-2v-8.9H25.5z"/>
            <path class="st0" d="M14.6,18.4l-5.8-7c0,0-0.9-0.8,0.1-0.8s3.2,0,3.2,0s0-0.6,0-1.4c0-2.4,0-6.9,0-8.7c0,0-0.1-0.5,0.6-0.5
		c0.8,0,4,0,4.6,0c0.5,0,0.5,0.4,0.5,0.4c0,1.8,0,6.4,0,8.7c0,0.8,0,1.3,0,1.3s1.8,0,3,0c1.2,0,0.3,0.9,0.3,0.9s-4.9,6.5-5.6,7.2
		C15.1,19,14.6,18.4,14.6,18.4z"/>
</g>
</svg>
      </a>
    </section>

    </div>
</template>
<script>
import FsLightbox from "fslightbox-vue/v3";
import FileSaver from 'file-saver';
import iso from "../../axios";

export default {
  components: { FsLightbox },
  data() {
    return {
      sss: false,
      toggler: false,
      slide:1,
      docs:[],
      CustomSource: [],
      lang:[],
    }
  },
  created() {
    iso.get('docs')
        .then(response => {
          this.docs = response.data
          for (let i = 0; i < this.docs.length; i++) {
            this.CustomSource.push(this.docs[i].img)
          }
        })
        .catch(error => {
          console.log(error);
        })

      iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    
  },
  methods: {
    fsOpen: function(){
      this.sss = true
    },
    fsClose: function(){
      this.sss = false
    },
    openLightboxOnSlide: function(number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
    download() {
      document.querySelectorAll('.fslightbox-flex-centered').forEach(item => {
        if(getComputedStyle(item).transform == 'matrix(1, 0, 0, 1, 0, 0)'){
          const url = item.getElementsByTagName('img')[0].src
          FileSaver.saveAs(url, "image.jpg");
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.st0{fill:#ddd;}
.downloadImage{
  position: fixed;
  right: 90px;
  top: 0;
  z-index: 99999999;
  height: 45px;
  width: 45px;
  cursor: pointer;
  box-sizing: border-box;
  display: none;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background:rgba(35,35,35,.65);
}.downloadImage svg{
   width: 20px
 }
.df{
  display: flex
}
.bilgiToplum{
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
table {
  flex: 1;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}
table tr td:first-child span:last-child{
  display: none;
}
table tr td:first-child span:first-child{
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  .bilgiToplum{
    flex-direction: column;
  }
  table tr {
    display: block;
    margin-bottom: .625em;
  }

  table td {
    display: block;
    font-size: .8em;
    text-align: right;
  }
  table tr td:last-child{
    display: none;
  }
  table tr td:first-child span:first-child{
    display: none;
  }
  table tr td:first-child span:last-child{
    display: block;
  }
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}


.header-haberler{
  position: relative;
  clear: both;
  width: 80%;
  margin-left:10%;
  margin-right:10%;
  text-align: left;
}

.header-haberler h3{
  font-family: bukis;
  color:#af1116;
  font-size:30px;
  margin:8px 0px 8px 0px;
}
.header-haberler h2{
  font-family: elyazi;
  font-size: 60px;
  margin:8px 0px 8px 0px;
  color:#af1116;
}


.duyurular-haberler{
  position: relative;
  clear: both;
  width: 100%;
}
.duyurular-haberler #bgcizgi{
  position: absolute;
  top:120px;
  left:0;
  width: 100%;
  z-index: -2;
}

.duyurular-haberler #duyurular{
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;
  grid-gap: 10px;
  justify-content: center;
}
.duyurular-haberler #duyurular .duyuru{
  width: 100vw;
  max-width: 350px;
  float: left;
  margin: 1.5vw;
  border-radius: 30px;
  background-color: #f5ebeb;
  color:#111111;
  text-decoration: none;
  transition: all ease .3s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.duyurular-haberler #duyurular .duyuru:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(255, 0, 0) 0px 30px 60px -30px;
  transform:  translateY(-20px);;
}
.duyurular-haberler #duyurular .duyuru .foto{
  width: 100%;
}
.duyurular-haberler #duyurular .duyuru .foto img{
  width: 100%;
  height: auto;
  border-radius: 30px 30px 0px 0px;
  object-fit: cover;
  max-height: 200px;
  object-position: top center;
}
.duyurular-haberler #duyurular .duyuru .cart{
  padding:20px;
}
.duyurular-haberler #duyurular .duyuru h3{
  margin-bottom: 10px;
  color:#af1116;
  font-size: 20px;
  font-weight: 600;
}
</style>